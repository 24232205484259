@font-face {
  font-family: "PPMontreal-Bold";
  src: url("/static/fonts/PPNeueMontreal-Bold.otf");
}

@font-face {
  font-family: "PPMontreal-Book";
  src: url("/static/fonts/PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "PPMontreal-SemiBoldItalic";
  src: url("/static/fonts/PPNeueMontreal-SemiBolditalic.otf");
}

@font-face {
  font-family: "Cosmo";
  src: url("/static/fonts/Cosmo-Regular.otf");
}

@font-face {
  font-family: "Cosmo";
  src: url("/static/fonts/Cosmo-Regular.eot");
  src: url("/static/fonts/Cosmo-Regular.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/Cosmo-Regular.woff2") format("woff2"),
    url("/static/fonts/Cosmo-Regular.woff") format("woff"),
    url("/static/fonts/Cosmo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/static/fonts/HelveticaNeue.eot");
  src: url("/static/fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"),
    url("/static/fonts/HelveticaNeue.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeue.woff") format("woff"),
    url("/static/fonts/HelveticaNeue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("/static/fonts/HelveticaNeue-Bold.eot");
  src: url("/static/fonts/HelveticaNeue-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeue-Bold.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeue-Bold.woff") format("woff"),
    url("/static/fonts/HelveticaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Light";
  src: url("/static/fonts/HelveticaNeue-Light.eot");
  src: url("/static/fonts/HelveticaNeue-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeue-Light.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeue-Light.woff") format("woff"),
    url("/static/fonts/HelveticaNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Thin";
  src: url("/static/fonts/HelveticaNeueThin.eot");
  src: url("/static/fonts/HelveticaNeueThin.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeueThin.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeueThin.woff") format("woff"),
    url("/static/fonts/HelveticaNeueThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Thin";
  src: url("/static/fonts/HelveticaNeueThin.eot");
  src: url("/static/fonts/HelveticaNeueThin.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeueThin.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeueThin.woff") format("woff"),
    url("/static/fonts/HelveticaNeueThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Condensed Black";
  src: url("/static/fonts/HelveticaNeue-CondensedBlack.eot");
  src: url("/static/fonts/HelveticaNeue-CondensedBlack.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeue-CondensedBlack.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeue-CondensedBlack.woff") format("woff"),
    url("/static/fonts/HelveticaNeue-CondensedBlack.ttf") format("truetype"),
    url("/static/fonts/HelveticaNeue-CondensedBlack.svg#HelveticaNeue-CondensedBlack")
      format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Consensed Bold";
  src: url("/static/fonts/HelveticaNeue-CondensedBold.eot");
  src: url("/static/fonts/HelveticaNeue-CondensedBold.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HelveticaNeue-CondensedBold.woff2") format("woff2"),
    url("/static/fonts/HelveticaNeue-CondensedBold.woff") format("woff"),
    url("/static/fonts/HelveticaNeue-CondensedBold.ttf") format("truetype"),
    url("/static/fonts/HelveticaNeue-CondensedBold.svg#HelveticaNeue-CondensedBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HiraMaru";
  src: url("/static/fonts/HiraMaruProN-W4-AlphaNum.eot");
  src: url("/static/fonts/HiraMaruProN-W4-AlphaNum.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HiraMaruProN-W4-AlphaNum.woff2") format("woff2"),
    url("/static/fonts/HiraMaruProN-W4-AlphaNum.woff") format("woff"),
    url("/static/fonts/HiraMaruProN-W4-AlphaNum.ttf") format("truetype"),
    url("/static/fonts/HiraMaruProN-W4-AlphaNum.svg#HiraMaruProN-W4-AlphaNum")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HiraKaku";
  src: url("/static/fonts/HiraKakuStd-W8-AlphaNum.eot");
  src: url("/static/fonts/HiraKakuStd-W8-AlphaNum.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/HiraKakuStd-W8-AlphaNum.woff2") format("woff2"),
    url("/static/fonts/HiraKakuStd-W8-AlphaNum.woff") format("woff"),
    url("/static/fonts/HiraKakuStd-W8-AlphaNum.ttf") format("truetype"),
    url("/static/fonts/HiraKakuStd-W8-AlphaNum.svg#HiraKakuStd-W8-AlphaNum")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color1: #fecd06;
  --color2: #5f6b9b;
  --color3: #11151c;
  --color4: #b3b6b7;
  --color5: #fdfff5;

  /* --color1: #ed9648;
  --color2: #7826df;
  --color3: #8ee379; */

  /* --color1: #ca3e3e;
  --color2: #2d12d6;
  --color3: #ffc93b; */
}

::selection {
  background: black !important; /* WebKit/Blink Browsers */
  color: white !important;
}
::-moz-selection {
  background: black !important; /* Gecko Browsers */
  color: white !important;
}

* {
  margin: 0;
  padding: 0;
  cursor: none !important;
}

html,
body {
  background: #000;
}

.webgl {
  /* position: fixed;
  top: 0;
  left: 0;
  outline: none; */
}

body {
  font-family: "Helvetica Neue", sans-serif, Arial;
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: var(--color5);
  background-color: var(--color5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HiraMaru", sans-serif, Arial;
  font-weight: 700 !important;
}

.text-dark {
  color: var(--color3);
}

.text-milk {
  color: var(--color5);
}

.bg-milk {
  background-color: var(--color5);
}

/* .semi-bold {
  font-family: "PPMontreal-SemiBoldItalic", sans-serif, Arial;
} */

.font-book {
  font-family: "Helvetica Neue Thin", sans-serif, Arial;
}

.cosmo {
  font-family: "HiraMaru", sans-serif, Arial !important;
  text-transform: uppercase;
}

/* Cursor */
.cursor {
  position: fixed;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f5f5f5;
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 999;

  transition: transform 0.2s;
}

/* General setup */
.hover-red:hover {
  color: var(--color1);
  transition: 0.2s ease;
}

.inner-character {
  transition: all 200ms ease;
}

.animated-character {
  position: relative;
}

.animated-hover-space {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.hero-word {
  display: flex;
}

/* #cursor {
  display: none;
  position: absolute;
  z-index: 50;
  margin-top: 10px;
  margin-left: 10px;
} */

#mouse-cursor {
  position: absolute;
  z-index: 50;
}

.bg-color-1 {
  background: var(--color1);
}

.bg-color-2 {
  background: var(--color2);
}

.bg-color-3 {
  background: var(--color3);
}

.bg-color-4 {
  background: var(--color4);
}

.bg-color-5 {
  background: var(--color5);
}

.title-lg {
  font-size: 7em;
}

/* Hero video */
.hero-underline {
  color: var(--color1);
}

.hero-word {
  opacity: 0;
}

/* Smooth Scrolling */

.pin-spacer {
  padding-bottom: 0 !important;
}

#services {
  color: #fff;
}

@media screen and (max-width: 768px) {
  #services {
    color: #000;
  }
}

#services-mobile {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
#services-mobile::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#services #panels-container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  width: 3000px !important;
  /* background-color: #ddd; */
}
#services #panels-container .panel {
  position: relative;
  width: 600px;
  height: 100vh;
  overflow: hidden;
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  display: flex;
  color: #333;
  text-align: left;

  padding: 50px 0;
}
#services #panels-container .panel img {
  max-width: 100%;
  height: auto;
  display: block;
}
#services #panels-container .panel .panels-navigation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
#services #panels-container .panel .anchor-panel,
#services #panels-container .panel .nav-panel {
  color: #000;
  text-transform: uppercase;
  margin-right: 2rem;
}
#services #panels-container .panels-navigation {
  position: absolute;
  width: 100%;
  bottom: 2rem;
  right: 2rem;
}

/* Splash page */
.splash {
  background: #000;
  z-index: 50;
}

.reveal,
.reveal2 {
  background: black;
  width: 200%;
}

.middle-m {
  width: 90%;
  top: 6px;
  left: 3px;
}

#inside {
  visibility: hidden;
}

/* .reveal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.reveal img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform-origin: left;
}

.reveal {
  visibility: hidden;
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 500px;
  overflow: hidden;
} */

/* Morph SVG Test */
/* svg {
  display: block;
  margin: 20px auto;
  width: auto;
} */

#hippo,
#elephant,
#star,
#logo1,
#inside,
#circle,
#part1,
#part2,
#part3,
#unmute,
#m-inside {
  visibility: hidden;
}

#slider {
  width: 420px;
  margin: 20px auto;
}

/* Hover buttons */

.button_su {
  overflow: hidden;
  position: relative;
  display: inline-block;

  margin-top: 15px;
}

.su_button_circle {
  background-color: red;
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
  /*animation-timing-function: ease-in-out; */
}

.button_su_inner {
  display: inline-block;
  background: #000;
  color: #f4f4f4;
  font-size: 8px;
  font-weight: 500;
  width: auto;
  text-align: center;

  transition: 400ms;
  text-decoration: none;
  padding: 10px 8px;
  z-index: 100000;
  text-transform: uppercase;
  border: 1px solid #fff;
  opacity: 0;
}

.button_text_container {
  position: relative;
  z-index: 10000;
}

.explode-circle {
  animation: explode 0.5s forwards;
}

.desplode-circle {
  animation: desplode 0.5s forwards;
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  100% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
  }
}

@keyframes desplode {
  0% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

/* Hamburger */

.row .three {
  padding: 80px 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: var(--color5);
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .hamburger .line {
    width: 20px;
    height: 2px;
    margin: 6px auto;
  }
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-7.is-active .line:nth-child(1) {
  width: 30px;
}

#hamburger-7.is-active .line:nth-child(2) {
  width: 40px;
}

#hamburger-7.is-active .line {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

/* Menu */
#menu-links a {
  font-size: 3em;
  line-height: 1.2em;
  color: var(--color5);
}

@media screen and (max-width: 768px) {
  #menu-links a {
    font-size: 2em;
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #fff;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--color1);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Client Slider */

/* Animations */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  height: 60px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}
.slider .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(200px * 32);
}
.slider .slide {
  height: 60px;
  width: 80px;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  z-index: 30;
  filter: invert(5%) sepia(8%) saturate(7383%) hue-rotate(240deg)
    brightness(117%) contrast(114%);
  transition: 0.4s ease;
}

/* Work  */

/* #work {
  position: relative;
  overflow: visible;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  grid-template-rows: masonry;
  gap: 0.8em;
} */

.work-video-desktop {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  vertical-align: middle;
}

/* Main CSS */
.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .grid-wrapper > div > video,
.grid-wrapper > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;

} */

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));

  grid-auto-flow: dense;
  padding: 8em;
}

@media screen and (max-width: 1200px) {
  .grid-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)) !important;
    /* grid-auto-rows: 300px; */
  }
}

@media screen and (max-width: 768px) {
  .grid-wrapper {
    grid-gap: 10px;
    /* grid-auto-rows: 300px; */
  }
}
/* .grid-wrapper .wide {
  grid-column: span 2;
}
.grid-wrapper .tall {
  grid-row: span 2;
}
.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
} */

// Work masonry
#work-inner {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); */
  grid-template-columns: repeat(3, 1fr);

  grid-auto-flow: dense;
  grid-template-rows: masonry;
  /*   grid-auto-rows: 100px; */

  gap: 0.8em;
}

#work-inner .work-video-grid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
/* #work-inner .work-video-grid:nth-child(odd) {
  grid-column: span 1;
  grid-row: span 2; */
/* } */
/* .container img:nth-child(even) {
  grid-column: span 1;
  grid-row: span 1;
} */
/* .container img:nth-child(3n + 1) {
  grid-column: span 2;
} */
/* #work .work-video-grid:nth-child(3n + 2) {
  grid-column: span 2;
  grid-row: span 1;
} */
/* img {
  max-width: 100%;
} */

/* .work-inner {
} */

/* @media screen and (max-width: 700px) {
  .work-inner {
    padding: 30px;
    left: 0;
    top: 0;
  }
} */

// Adjust each video individually on big screens

.work-video {
  z-index: 60;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 3px;
  padding-bottom: calc(9 / 16 * 100%) !important;
  /* width: 20%; */

  /* -webkit-box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.33); */
  /* box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.33); */
}

.work-video {
  display: flex !important;
}
.work-video:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(9 / 16 * 100%) !important;
}

.bg,
.overlay {
  text-align: center;
}

.overlay {
  transition: 0.3s all;
  border-radius: 3px;
  color: var(--color5);
}

.bg {
  float: left;
  width: 100%;
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
}
.bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: var(--color5);
  opacity: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg:hover .overlay {
  opacity: 1;
}
.bg-hover-blur:hover video,
.bg-hover-blur:hover img {
  -webkit-filter: blur(6px);
  filter: blur(6px);
}

.bg iframe {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

/* @media screen and (max-width: 1148px) {
  .bg {
    max-width: 48%;
    margin: 1%;
  }
}
@media screen and (max-width: 768px) {
  .bg {
    float: none;
    max-width: 80%;
    margin: 1% auto;
  }
} */

/* Team */
@media screen and (max-width: 768px) {
  #team {
  }
}

/* Contact form */
form input,
form textarea {
  width: 100%;
  background: #000;
  border-bottom: 1px solid white;
  padding: 4px 2px;
  font-weight: 200;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
form button,
.splash button,
.primary-btn {
  background-color: var(--color5);
  color: #000;
  width: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.7em;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif, Arial;
  border-radius: 9999px;
  cursor: pointer;
}

.mce_inline_error {
  background: black !important;
  font-size: 0.7em;
}

.btn1:hover {
  background: var(--color1);
  color: white !important;
  border: none !important;
}

.btn2:hover {
  background: var(--color2);
  color: white !important;
  border: none !important;
}

.btn3:hover {
  background: var(--color3);
  color: white !important;
  border: none !important;
}

.mask2 {
  -webkit-mask-image: radial-gradient(
    circle at 40% 40%,
    black 80%,
    rgba(0, 255, 255, 0.6) 50%
  );

  mask-image: radial-gradient(
    ellipse 50% 50% at 40% 40%,
    black 40%,
    transparent 50%
  );
}

.overlay h2 {
  font-family: "Helvetica Neue Condensed Black", sans-serif;
  text-transform: uppercase;
}

.overlay h4 {
  font-family: "Helvetica Neue Bold", sans-serif;
  /* Italic */
  font-style: italic;
  text-transform: uppercase;
}

.condensed-black {
  font-family: "Helvetica Neue Condensed Black", sans-serif;
  text-transform: uppercase;
}

.condensed-bold {
  font-family: "Helvetica Neue Condensed Bold", sans-serif !important;
  font-weight: bold;
  text-transform: uppercase;
}

.contact-input {
  background: rgba(0, 0, 0, 0);
  border-bottom: 2px solid black;
}

.contact-btn {
  background: black !important;
  color: white !important;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  font-family: "Helvetica Neue Condensed Bold", sans-serif, Arial !important;
  border-radius: 0 !important;
  cursor: pointer;
  text-transform: uppercase;
}

.video-z {
  z-index: 30;
}

@media screen and (min-width: 768px) {
  .video-z {
    z-index: 85;
  }
}
